.subscription-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
    padding: 20px;
    margin: 20px;
}

@media screen and (max-width: 768px) {
    .subscription-container {
        margin-left: 0;
        width: 100%;
        padding: 10px;
    }
}

.dataGridContainer {
    flex-grow: 1;
    width: 100%;
    margin-top: 2px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.subscriptions {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 100%;
}