.userRidesFiltered {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
    padding: 20px;
    margin: 20px;
}

@media screen and (max-width: 768px) {
    .userRidesFiltered {
        margin-left: 0;
        width: 100%;
        padding: 10px;
    }
}

.dataGridContainer {
    flex-grow: 1;
    width: 100%;
    margin-top: 2px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    overflow-x: auto;

}
.user-rides-container{
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 105%;
}
/* UserRidesFiltered.css */
.form-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding:10px;
}
.datepicker-filterRides{
    width:20%

}
.autocomplete-filterRides{
    width: 20%;
}

.submit-button {
    padding:10px
}
.completed-ride {
    background-color: #00796b !important;
}

.cancelled-ride {
    background-color: #ef5350 !important;
}

.upcoming-ride {
    background-color: #2979ff !important;
}
.rescheduled-ride{
    background-color: #afb42b !important;
}